import * as React from "react";
import arrow from "./dropdown-arrow.svg";

const SERIES_LIST = ["preseed", "seed", "A", "B", "C", "D", "E", "F"];

interface SeriesJoinedDropdownProps {
  joinedSeries: string;
  setJoinedSeries: React.Dispatch<React.SetStateAction<string>>;
}

export const SeriesJoinedDropdown: React.FC<SeriesJoinedDropdownProps> = ({
  joinedSeries,
  setJoinedSeries,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setJoinedSeries(event.target.value);
  };

  return (
    <div className="w-30 flex flex-col items-center justify-center gap-2">
      <select
        id="series-joined"
        value={joinedSeries}
        onChange={handleChange}
        style={{
          width: "100%",
          height: "40px",
          padding: "6px 20px 6px 6px",
          borderRadius: "20px",
          color: "white",
          backgroundColor: "rgb(252, 114, 255)",
        }}>
        {SERIES_LIST.map((series) => (
          <option key={series} value={series}>
            {series.charAt(0).toUpperCase() + series.slice(1)}
          </option>
        ))}
      </select>
    </div>
  );
};
