interface LandingOfferProps {
  landingClicked: boolean;
  setLandingClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

export function LandingOffer({
  landingClicked,
  setLandingClicked,
}: LandingOfferProps) {
  if (landingClicked) {
    return null;
  }
  return (
    <div className="h1-wrapper">
      <h1>
        How Much is Your Offer <span className="italic">Truly</span> Worth?
      </h1>
      <div className="flex flex-col w-full items-center justify-center space-y-8 mt-4">
        <h2 className="text-2xl font-medium text-center px-[10%]">
          Calculate your potential dilution & present day value of your equity
        </h2>
        <button
          className="h-12 px-20 flex items-center justify-center rounded-[20px] bg-white-secondary text-sm font-semibold bottom-[-18px]"
          onClick={() => setLandingClicked(true)}>
          Get Started
        </button>
      </div>
    </div>
  );
}
