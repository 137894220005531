import React from "react";
import question from "../question.png";

interface FeedbackTooltipProps {
  message: string;
}

const FeedbackTooltip: React.FC<FeedbackTooltipProps> = ({ message }) => (
  <div className="tooltip ml-2">
    <img src={question} alt="question-tooltip" className="h-6" />
    <div className="tooltip-content">{message}</div>
  </div>
);

export default FeedbackTooltip;
