import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import { Round } from "./utils/round";
import { SeriesJoinedDropdown } from "./components/SeriesDropdown";
import TemplateDropdown from "./components/TemplateDropdown";
import { TEMPLATES } from "./utils/templates";
import ValuationTable from "./components/ValuationTable";
import ComponentWrapper from "./components/Shared/ComponentWrapper";
import { AiOutlinePlus } from "react-icons/ai";
import SimpleInput from "./components/Inputs/ValuationInput";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { Header } from "./components/Header";
import { OfferDetails } from "./components/OfferDetails";
import { formatNumber } from "./utils/format";
import { CalculatedOffer } from "./components/CalculatedOffer";
import { LandingOffer } from "./components/LandingOffer";
import { ExampleRounds } from "./components/ExampleRounds";
import arrow from "./slice-arrow.png";

const SERIES_LIST = ["preseed", "seed", "A", "B", "C", "D", "E", "F"];

function roundsEqual<T>(a: T[], b: T[]): boolean {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) return false;
  }
  return true;
}

function App() {
  const [landingClicked, setLandingClicked] = useState(false);
  const [initialOwnershipPercentage, setInitialOwnershipPercentage] =
    useState<number>(0.1);
  const [rounds, setRounds] = useState<Round[]>([]);
  const [joinedSeries, setJoinedSeries] = useState<string>("preseed");
  const [calculate, setCalculate] = useState(false);
  const [currEquityValue, setCurrEquityValue] = useState(0);
  const [currDilutionPercentage, setCurrDilutionPercentage] = useState(0);

  const addRound = () => {
    const nextSeriesIndex =
      SERIES_LIST.indexOf(joinedSeries) + 1 + rounds.length;
    if (nextSeriesIndex < SERIES_LIST.length) {
      setRounds([
        ...rounds,
        { amount: 0, valuation: 0, series: SERIES_LIST[nextSeriesIndex] },
      ]);
    }
  };

  const getCalculatedEquity = () => {
    if (rounds.length === 0) return 0;
    return (
      (initialOwnershipPercentage / 100) *
        rounds[rounds.length - 1].valuation || 0
    );
  };

  useEffect(() => {
    let currentOwnershipFraction = initialOwnershipPercentage / 100;
    let runningDilutionFactor = 1; // Represents cumulative non-dilution through all rounds

    const joinedSeriesIndex = SERIES_LIST.indexOf(joinedSeries);

    const updatedRounds: Round[] = rounds.map((round, index) => {
      const seriesIndex = SERIES_LIST.indexOf(round.series);
      const dilution = round.amount / round.valuation;

      // If this round happened before the employee joined, don't apply any dilution and set equity to 0.
      if (seriesIndex < joinedSeriesIndex) {
        return {
          ...round,
          dilution: dilution * 100,
          equityValue: 0,
          totalDilution: 0,
        };
      }

      if (seriesIndex === joinedSeriesIndex) {
        return {
          ...round,
          dilution: dilution * 100,
          equityValue: round.valuation * currentOwnershipFraction,
          totalDilution: 0,
        };
      }

      runningDilutionFactor *= 1 - dilution;
      const ownershipAfterThisRound =
        currentOwnershipFraction * runningDilutionFactor;

      const totalDilutionPercentage =
        100 * (1 - ownershipAfterThisRound / currentOwnershipFraction);

      const currentEquityValue = ownershipAfterThisRound * round.valuation;

      return {
        ...round,
        dilution: dilution * 100,
        equityValue: currentEquityValue,
        totalDilution: totalDilutionPercentage,
      };
    });

    if (!roundsEqual(rounds, updatedRounds)) {
      setRounds(updatedRounds);
    }
  }, [initialOwnershipPercentage, rounds, joinedSeries]);

  useEffect(() => {
    const joinedSeriesIndex = SERIES_LIST.indexOf(joinedSeries);

    if (
      rounds.length > 0 &&
      SERIES_LIST.indexOf(rounds[rounds.length - 1].series) >= joinedSeriesIndex
    ) {
      setCurrEquityValue(
        rounds[rounds.length - 1].equityValue || currEquityValue
      );
      setCurrDilutionPercentage(
        rounds[rounds.length - 1].totalDilution || currDilutionPercentage
      );
    } else {
      const calculatedEquity = getCalculatedEquity();
      setCurrEquityValue(calculatedEquity);
    }
  }, [rounds, joinedSeries]);

  useEffect(() => {
    if (rounds.length > 0 && !calculate) {
      setCalculate(true);
    }
  }, [rounds]);

  const handleRemoveRound = (roundIndex: number) => {
    const updatedRounds = [...rounds];
    updatedRounds.splice(roundIndex, 1);

    // Update series values for rounds after the removed round
    for (let i = roundIndex; i < updatedRounds.length; i++) {
      const prevSeriesIndex = SERIES_LIST.indexOf(updatedRounds[i].series);
      if (prevSeriesIndex > 0) {
        updatedRounds[i].series = SERIES_LIST[prevSeriesIndex - 1];
      }
    }

    setRounds(updatedRounds);
  };

  const handleClickCalculate = () => {
    const calculatedEquity = getCalculatedEquity();
    setCurrEquityValue(calculatedEquity);

    setCalculate(true);
  };

  useEffect(() => {
    if (rounds.length > 0) {
      setCurrEquityValue(
        rounds[rounds.length - 1].equityValue || currEquityValue
      );
      setCurrDilutionPercentage(
        rounds[rounds.length - 1].totalDilution || currDilutionPercentage
      );
    }
  }, [rounds, joinedSeries]);

  useEffect(() => {
    if (rounds.length < 1) {
      const calculatedEquity = getCalculatedEquity();
      setCurrEquityValue(calculatedEquity);
    }
  }, [initialOwnershipPercentage, rounds, joinedSeries]);

  return (
    <div className="gradient items-start justify-center flex flex-col">
      {/* Header Section Start */}
      <Header />
      <div className="w-full flex flex-col h-full items-center justify-center py-8 gap-10 overflow-y-hidden">
        {/* offer section */}
        <div className="flex flex-row offer-wrapper relative items-center">
          <OfferDetails
            calculate={calculate}
            setLandingClicked={setLandingClicked}
            landingClicked={landingClicked}
            initialOwnershipPercentage={initialOwnershipPercentage}
            setInitialOwnershipPercentage={setInitialOwnershipPercentage}
            joinedSeries={joinedSeries}
            setJoinedSeries={setJoinedSeries}
            handleClickCalculate={handleClickCalculate}
          />
          {/* {calculate && <img src={arrow} alt="Arrow" className="w-16" />} */}
          <CalculatedOffer
            initialOwnershipPercentage={initialOwnershipPercentage}
            calculate={calculate}
            currEquityValue={currEquityValue}
            currDilutionPercentage={currDilutionPercentage}
          />

          <LandingOffer
            landingClicked={landingClicked}
            setLandingClicked={setLandingClicked}
          />
        </div>
        {landingClicked && calculate && (
          <div className="flex flex-col flex-grow max-h-[60%] space-y-4">
            {/* funding rounds */}
            <ExampleRounds setRounds={setRounds} />
            <ValuationTable
              addRound={addRound}
              rounds={rounds}
              formatNumber={formatNumber}
              handleRemoveRound={handleRemoveRound}
              onAmountChange={(index, newValue) => {
                setRounds((prevRounds) => {
                  const newRounds = [...prevRounds];
                  newRounds[index].amount = newValue;
                  return newRounds;
                });
              }}
              onValuationChange={(index, newValue) => {
                setRounds((prevRounds) => {
                  const newRounds = [...prevRounds];
                  newRounds[index].valuation = newValue;
                  return newRounds;
                });
              }}
            />

            {rounds.length > 0 && (
              <button
                onClick={addRound}
                className="h-[67px] w-full flex items-center justify-center gap-2 text-sm font-medium bg-white-off rounded-[9px] mb-8">
                Add Funding Round <AiOutlinePlus className="text-lg" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
