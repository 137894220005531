import { Round } from "../utils/round";
import { Template, TEMPLATES } from "../utils/templates";
import FeedbackTooltip from "./FeedbackTooltip";
import TemplateDropdown from "./TemplateDropdown";

interface ExampleRoundsProps {
  setRounds: React.Dispatch<React.SetStateAction<Round[]>>;
}

export function ExampleRounds({ setRounds }: ExampleRoundsProps) {
  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const chosenTemplate = TEMPLATES.find((t) => t.name === e.target.value);
    if (chosenTemplate) {
      setRounds(chosenTemplate.rounds);
    }
  };

  return (
    <div className="w-full flex flex-col justify-start gap-6 items-start">
      <h2 className="text-2xl font-medium">Funding Rounds</h2>
      <div className="w-full flex items-center justify-start gap-6">
        <p className="text-sm font-normal">Templates:</p>
        {TEMPLATES.slice(0, 2).map((template: Template, index: number) => (
          <button
            key={index}
            onClick={() => setRounds(template.rounds)}
            className="h-[36px] px-7 flex items-center justify-center rounded-[20px] bg-white-secondary text-sm font-semibold">
            {template.name}
          </button>
        ))}
        <TemplateDropdown
          handleTemplateChange={handleTemplateChange}
          templates={TEMPLATES}
        />
        <FeedbackTooltip message="Do you find templates helpful?" />
      </div>
    </div>
  );
}
