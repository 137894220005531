import { Round } from "./round";

export interface Template {
  name: string;
  rounds: Round[];
}

export const TEMPLATES: Template[] = [
  {
    name: "E-Commerce Platform",
    rounds: [
      { amount: 300000, valuation: 3000000, series: "pre-seed" },
      { amount: 1000000, valuation: 7000000, series: "seed" },
      { amount: 6000000, valuation: 30000000, series: "A" },
      { amount: 18000000, valuation: 100000000, series: "B" },
      // E-commerce platforms might require substantial funding for inventory, logistics, or technology development.
    ],
  },
  {
    name: "GreenTech Startup",
    rounds: [
      { amount: 700000, valuation: 3500000, series: "pre-seed" },
      { amount: 2500000, valuation: 12000000, series: "seed" },
      { amount: 8000000, valuation: 40000000, series: "A" },
      { amount: 22000000, valuation: 130000000, series: "B" },
      // GreenTech or CleanTech startups might focus on sustainable energy, recycling, or environmental tech.
    ],
  },
  {
    name: "EdTech Platform",
    rounds: [
      { amount: 400000, valuation: 4000000, series: "pre-seed" },
      { amount: 1500000, valuation: 7500000, series: "seed" },
      { amount: 5500000, valuation: 28000000, series: "A" },
      // Education technology platforms might cater to online learning, school management systems, or other educational tools.
    ],
  },
  {
    name: "HealthTech Solution",
    rounds: [
      { amount: 600000, valuation: 3000000, series: "pre-seed" },
      { amount: 2200000, valuation: 11000000, series: "seed" },
      { amount: 7000000, valuation: 35000000, series: "A" },
      { amount: 20000000, valuation: 120000000, series: "B" },
      // HealthTech solutions might involve digital health records, telehealth platforms, or medical AI tools.
    ],
  },
  {
    name: "FinTech App",
    rounds: [
      { amount: 500000, valuation: 5000000, series: "pre-seed" },
      { amount: 1800000, valuation: 9000000, series: "seed" },
      { amount: 6500000, valuation: 32000000, series: "A" },
      // FinTech apps might revolve around digital banking, investment platforms, or other financial tools.
    ],
  },
];
