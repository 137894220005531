import * as React from "react";
import { Template } from "../utils/templates"; // make sure to import Template type correctly if needed

interface TemplateDropdownProps {
  handleTemplateChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  templates: Template[];
}

const NewTemplatesDropDown: React.FC<TemplateDropdownProps> = ({
  handleTemplateChange,
  templates,
}) => {
  const [selectedValue, setSelectedValue] = React.useState<string>("More");
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    handleTemplateChange(event);
  };

  return (
    <div className="w-30 flex flex-col items-center justify-center gap-2">
      <select
        id="template-dropdown"
        value={selectedValue}
        onChange={handleChange}
        style={{
          width: "100%",
          height: "40px",
          padding: "6px 20px 6px 6px",
          borderRadius: "20px",
          color: "white",
          backgroundColor: "rgb(252, 114, 255)",
          border: "1px solid rgb(252, 114, 255)",
        }}>
        <option value="More">More</option>
        {templates.map((template, idx) => (
          <option key={idx} value={template.name}>
            {template.name}
          </option>
        ))}
      </select>
    </div>
  );
};
export default NewTemplatesDropDown;
