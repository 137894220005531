import React from "react";

interface PercentageInputProps {
  min: number;
  max: number;
  step: number;
  value: number;
  setValue: (value: string) => void;
  label: string;
}
const PercentageInput: React.FC<PercentageInputProps> = ({
  value = 0,
  min,
  max,
  step,
  setValue,
  label,
}: PercentageInputProps) => {
  return (
    <div className="input-panel">
      <div className="inner-input-panel space-x-2">
        <div className="flex-1 flex-col align-left">
          <div className="input-label">{label}</div>
          <input
            min={min}
            max={max}
            step={step}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            style={{
              alignSelf: "left",
              fontFamily: "Inter",
              borderRadius: "9px",
              height: "40px",
              width: "100%",
              borderColor: "#e2e8f0",
              borderWidth: "2px",
              borderStyle: "solid",
              fontWeight: "485",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default PercentageInput;
