import { useMemo, useState } from "react";
import { formatNumber } from "../utils/format";

interface CalculatedOfferProps {
  initialOwnershipPercentage: number;
  calculate: boolean;
  currDilutionPercentage: number;
  currEquityValue: number;
}

export function CalculatedOffer({
  calculate,
  currDilutionPercentage,
  currEquityValue,
  initialOwnershipPercentage,
}: CalculatedOfferProps) {
  const [rate, setRate] = useState(7.5); // Default to 7.5% rate
  const [periods, setPeriods] = useState(8); // Default to 8 years

  const calculatePresentValue = (futureValue?: number) => {
    if (futureValue === undefined || isNaN(futureValue)) return 0;
    const presentValue = futureValue / Math.pow(1 + rate / 100, periods);
    return presentValue;
  };

  const currentOwnsershipPercentage = useMemo(() => {
    const ownership =
      (initialOwnershipPercentage * (100 - currDilutionPercentage)) / 100;
    return ownership;
  }, [initialOwnershipPercentage, currDilutionPercentage]);

  if (!calculate) {
    return null;
  }
  return (
    <div className={"offer clicked"}>
      <h2 className="text-2xl font-medium">Offer Details</h2>
      <div className="w-full flex items-center justify-start gap-6">
        <div className="flex flex-col space-y-4 w-full">
          <div className="flex flex-row items-center justify-start gap-2">
            <div className="flex flex-col items-center justify-start gap-2">
              <p className=" text-sm font-medium">Your Future Equity Value</p>
              <span className="w-full h-[56px] flex items-center justify-center rounded-[9px] bg-white-secondary text-lg text-center font-semibold">
                $
                {currEquityValue.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
            <div className="flex flex-col items-center justify-start gap-2">
              <p className=" text-sm font-medium">
                Your Future Percent Ownership
              </p>
              <span className="w-full h-[56px] flex items-center justify-center rounded-[9px] bg-white-secondary text-lg text-center font-semibold">
                {`${formatNumber(currentOwnsershipPercentage)}%`}
              </span>
            </div>
            <div className="flex flex-col items-center justify-start gap-2">
              <p className="text-sm font-medium">Your Total Dilution</p>
              <span className="w-full h-[56px] flex items-center justify-center rounded-[9px] bg-white-secondary text-lg text-center font-semibold">
                {`${formatNumber(currDilutionPercentage)}%`}
              </span>
            </div>
          </div>
          <h2 className="text-2xl font-medium align-start">
            Your Offer In Today's Dollars
          </h2>
          <div className="flex flex-col items-center justify-start gap-2">
            {calculate && (
              <div className="w-full flex items-center justify-end gap-10">
                <div>
                  <br />
                  <label>
                    Discount Rate (%):
                    <input
                      step={0.1}
                      type="number"
                      value={rate}
                      onChange={(e) => setRate(parseFloat(e.target.value))}
                    />
                  </label>
                  <br />
                  <label>
                    Years to Exit (years):
                    <input
                      step={1}
                      type="number"
                      value={periods}
                      onChange={(e) => setPeriods(parseInt(e.target.value, 10))}
                    />
                  </label>
                  <br />
                </div>
              </div>
            )}
          </div>
          <div className="items-center justify-start">
            <p className="flex-none m-0 text-md font-medium">
              Present Day Value:
            </p>
            <p className="flex-grow m-0 ml-2 text-3xl font-semibold">
              $
              {calculatePresentValue(currEquityValue).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
