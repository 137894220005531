import PercentageInput from "./Inputs/PercentageInput";
import { SeriesJoinedDropdown } from "./SeriesDropdown";

interface OfferDetailsProps {
  initialOwnershipPercentage: number;
  setInitialOwnershipPercentage: (value: number) => void;
  joinedSeries: string;
  setJoinedSeries: React.Dispatch<React.SetStateAction<string>>;
  landingClicked: boolean;
  setLandingClicked: (value: boolean) => void;
  handleClickCalculate: () => void;
  calculate: boolean;
}

export function OfferDetails({
  initialOwnershipPercentage,
  setInitialOwnershipPercentage,
  landingClicked,
  setLandingClicked,
  calculate,
  handleClickCalculate,
  joinedSeries,
  setJoinedSeries,
}: OfferDetailsProps) {
  return (
    <div
      className={landingClicked ? "offer clicked" : "offer"}
      onClick={() => setLandingClicked(true)}>
      <h2 className="text-2xl font-medium">Your Offer</h2>
      <div className="w-full flex items-center justify-start gap-6">
        <div className="flex flex-col space-y-4 w-full">
          <PercentageInput
            min={0.0}
            max={100}
            step={0.05}
            label="Current Percent Ownership (%)"
            value={initialOwnershipPercentage}
            setValue={(valueString: string) => {
              setInitialOwnershipPercentage(Number(valueString)); // Convert the string to a number.
            }}
          />
          <div className="flex flex-col items-start justify-center pl-[16px]">
            <div className="input-label">Last Closed Round:</div>
            <SeriesJoinedDropdown
              joinedSeries={joinedSeries}
              setJoinedSeries={setJoinedSeries}
            />
          </div>
        </div>
      </div>
      {!calculate && (
        <div className="flex w-full justify-center items-center pt-4">
          <button className="w-full" onClick={handleClickCalculate}>
            Calculate
          </button>
        </div>
      )}
    </div>
  );
}
