import React, { useEffect, useRef } from "react";
import { CurrencyInput } from "./Inputs/CurrencyInput";
import { Round } from "../utils/round";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import arrow from "../arrow.png";

interface ValuationTableProps {
  rounds: Round[];
  formatNumber: (value?: number, suffix?: string) => string;
  handleRemoveRound: (index: number) => void;
  addRound: () => void;
  onAmountChange: (index: number, newValue: number) => void;
  onValuationChange: (index: number, newValue: number) => void;
}

export const NewTable = ({
  addRound,
  rounds,
  handleRemoveRound,
  formatNumber,
  onAmountChange,
  onValuationChange,
}: ValuationTableProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, [rounds]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden items-center justify-start gap-5">
      {/* table */}
      <div className="flex flex-col w-full h-full">
        {/* headers */}
        <div className="grid grid-cols-[.5fr,.5fr,1.5fr,1.5fr,1fr,1fr,1fr] place-items-center gap-6">
          <p></p>
          <p className="text-sm text-start font-semibold">Series</p>
          <p className="text-sm text-start font-semibold">Amount Raised ($)</p>
          <p className="text-sm text-start font-semibold">
            Post-Money Valuation ($)
          </p>
          <p className="text-sm text-start font-semibold">Round Dilution</p>
          <p className="text-sm text-start font-semibold">
            Running Equity Value
          </p>
          <p className="text-sm text-start font-semibold">Running Dilution</p>
        </div>

        {/* Scrollable Rows */}
        <div
          className={`flex-grow ${
            rounds.length > 0 ? "overflow-y-scroll" : ""
          }`}
          style={{ maxHeight: "80vh" }}
          ref={scrollContainerRef}>
          {rounds.length === 0 ? (
            <div
              className="flex flex-col m-4 p-4 justify-center items-center h-[90%] bg-white rounded-xl table-landing"
              onClick={addRound}>
              <img
                src={arrow}
                className="animate-bounce w-6 h-6"
                alt="click-arrow"></img>
              <p className="text-center">
                Click Here to add hypothetical funding rounds & see how your
                equity grows over time
              </p>
            </div>
          ) : (
            rounds.map((round, index) => (
              <div
                className="grid grid-cols-[.5fr,.5fr,1.5fr,1.5fr,1fr,1fr,1fr] place-items-center gap-6 py-5 border-b-2 border-b-white-secondary"
                key={index}>
                <div className="w-full flex items-center justify-center">
                  <Tooltip title="Delete">
                    <span
                      className="cursor-pointer text-red-500 hover:text-red-700"
                      onClick={() => handleRemoveRound(index)}>
                      <DeleteIcon />
                    </span>
                  </Tooltip>
                </div>
                <div className="w-full flex items-center justify-center text-xs font-medium">
                  Series {round.series}
                </div>
                <div>
                  <CurrencyInput
                    value={round.amount}
                    onChange={(newValue) => {
                      onAmountChange(index, newValue);
                    }}
                  />
                </div>
                <div>
                  <CurrencyInput
                    value={round.valuation}
                    onChange={(newValue) => {
                      onValuationChange(index, newValue);
                    }}
                  />
                </div>
                <div className="w-full h-full flex items-center justify-center text-smfont-medium">
                  {formatNumber(round.dilution, "%")}
                </div>
                <div className="w-full h-full flex items-center justify-center text-sm font-medium">
                  $ {formatNumber(round.equityValue, "")}
                </div>
                <div className="w-full h-full flex items-center justify-center text-sm font-medium">
                  {formatNumber(round.totalDilution, "%")}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default NewTable;
